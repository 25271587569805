
export enum EMetricType {
  // 满意度问卷
  CSAT = 0,
  // 推荐度问卷
  NPS = 1,
  // 费力度问卷
  CES = 2,
}

/**
 * 拖拽组件
 */
export interface ISortableChangeEvent {
  /**
   * 移动变换位置时
   */
  moved?: {
    element: IQuestionItem;
    newIndex: number;
    oldIndex: number;
  };
  /**
   * 左侧拖动组件到右侧新增时
   */
  added?: {
    element: IQuestionItem[];
    newIndex: number;
  };
}

export enum EQuestionItemType {
  /**
   * 量表题
   */
  Star = 'star',
  /**
   * 文本框
   */
  Text = 'text',
  /**
   * 多选模式
   */
  Option = 'option',
  /**
   * 敏感信息输入框
   */
  SensitiveInput = 'sensitive_input',
  /**
   * 插入图片
   */
  Image = 'image',
  /**
   * 矩阵题
   */
  Matrix = 'matrix',
}

/**
 * 问卷子类型
 */
export enum EQuestionItemSubType {
  /**
   * 评价打分
   */
  STAR_STAR = 'star_star',
  /**
   * 量表NPS
   */
  STAR_TEXT = 'star_text',
  /**
   * 矩阵单选
   */
  MATRIX_RADIO = 'matrix_radio',
  /**
   * 矩阵多选
   */
  MATRIX_CHECKBOX = 'matrix_checkbox',
  /**
   * 单选
   */
  RADIO = 'radio',
  /**
   * 多选
   */
  CHECKBOX = 'checkbox',
  /**
   * 图片单选
   */
  IMAGE_RADIO = 'image_radio',
  /**
   * 图片多选
   */
  IMAGE_CHECKBOX = 'image_checkbox',
  /**
   * 单行文本
   */
  TEXT = 'text',
  /**
   * 多行文本
   */
  TEXTAREA = 'textarea',
  /**
   * 敏感题
   */
  SENSITIVE_INPUT = 'sensitive_input',
}

/**
 * 指标正向负向标记
 */
export enum EQuestionOptionTendency {
  /**
   * 正常
   */
  NEUTRAL = 0,
  /**
   * 负向
   */
  NEGATIVE = 1,
  /**
   * 正向
   */
  POSITIVE = 2,
}

export interface IQuestionOptionItem {
  id: string;
  text: string;
  exclusive?: boolean;
  goto?: string;
  /**
   * 用于关联下一题
   */
  displays: string[];
  // 是否固定出现
  is_fixed: boolean;
  tendency: EQuestionOptionTendency;
  index_id: string;
  parent_index_id?: string;
  // 选项带图片的选择题
  image: string;
  is_deleted?: boolean;
}

export interface IQuestionStarStyle {
  star_question_render_style: 'text' | 'emoji';
}

/**
 * 题干：量表题
 */
export interface IQuestionStar extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Star;
  sub_type: EQuestionItemSubType.STAR_STAR | EQuestionItemSubType.STAR_TEXT;
  style: string;
  title: string;
  description: string;
  required?: boolean;
  options: IQuestionOptionItem[];
  index_id: string;
  parent_index_id?: string;
  is_deleted?: boolean;
}

/**
 * 横纵比例
 */
export enum EAspectRatio {
  ONE_TO_ONE = '1:1',
  FOUR_TO_THREE = '4:3',
  SIXTEEN_TO_NINE = '16:9',
  THREE_TO_FOUR = '3:4',
  NINE_TO_SIXTEEN = '9:16'
}

export interface IQuestionOptionStyle {
  line_items: string;
  max?: string;
  /**
   * 是否互斥
   */
  multiple?: boolean;
  /**
   * 图片比例
   */
  image_aspect_ratio?: EAspectRatio;
}

/**
 * 题干：多选框
 */
export interface IQuestionOption extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Option;
  sub_type: EQuestionItemSubType.RADIO | EQuestionItemSubType.CHECKBOX | EQuestionItemSubType.IMAGE_RADIO | EQuestionItemSubType.IMAGE_CHECKBOX;
  style: string;
  title: string;
  description: string;
  required?: boolean;
  options: IQuestionOptionItem[];
  // 为option时，子元素是否随机展示
  is_random?: boolean;
  is_deleted?: boolean;
  /**
   * 最大可展现选项数量（默认为9，最小为2）
   */
  option_max_cnt: string;
}

export interface IQuestionSubTitle {
  /**
   * 行标题id
   */
  id: string;
  /**
   * 行标题
   */
  text: string;
  /**
   * 是否已删除，用于更新接口
   */
  is_deleted: boolean;
}

/**
 * 矩阵题展示样式
 */
export enum EQuestionMatrixDisplayStyle {
  /**
   * 列表形式
   */
  MATRIX = 'matrix',
  /**
   * 选择题形式
   */
  OPTION = 'option',
}

/**
 * 矩阵特殊样式
 */
export interface IQuestionMatrixStyle {
  line_items?: string;
  display_style: EQuestionMatrixDisplayStyle;
  max?: string;
}

/**
 * 高级题型
 */
export enum EAdvancedType {
  EMPTY = '',
  KANO = 'kano',
}

/**
 * 题干：矩阵题
 */
export interface IQuestionMatrix extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Matrix;
  sub_type: EQuestionItemSubType.MATRIX_RADIO | EQuestionItemSubType.MATRIX_CHECKBOX;
  sub_titles: IQuestionSubTitle[];
  style: string;
  title: string;
  description: string;
  required?: boolean;
  options: IQuestionOptionItem[];
  // 为option时，子元素是否随机展示
  is_random?: boolean;
  is_deleted?: boolean;
  /**
   * 引用其他多选题的题目id，最后保存时根据这个id匹配多选题并使用它的options填充至sub_titles
   */
  refer?: string;
}

/**
 * 题干：文本框-开放题选项
 */
export interface IQuestionText extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.Text;
  sub_type: EQuestionItemSubType.TEXT | EQuestionItemSubType.TEXTAREA;
  title: string;
  description: string;
  required?: boolean;
  is_deleted?: boolean;
  // index_id: string;
}

/**
 * 敏感信息输入框定制化面板参数
 */
export interface IQuestionSensitiveInputStyle {
  // 是否需要填答者协议和腾讯问卷隐私政策1为是，0为否
  license: number;
}

export interface IQuestionSensitiveInput extends IQuestionItemBase {
  id: string;
  type: EQuestionItemType.SensitiveInput;
  sub_type: EQuestionItemSubType.SENSITIVE_INPUT;
  style: string;
  // 标题
  title: string;
  // 描述（副标题）
  description: string;
  // 文本框placeholder
  placeholder: string;
  required?: boolean;
  is_deleted?: boolean;
}

/**
 * 问题Base类
 */
export interface IQuestionItemBase {
  /**
 * 高级题型
 */
  advanced_type?: EAdvancedType;
  /**
   * 高级题型uuid，用于联动等操作
   */
  advanced_id?: string;
  /**
   * 正负向（高级题型kano时，拖拽一条kano会生成两道题，一道是正向一道反向）
   */
  tendency?: EQuestionOptionTendency;
}

/**
 * 题干对象（可能为量表题、选项题或者开放题）
 */
export type IQuestionItem = IQuestionStar | IQuestionOption | IQuestionMatrix | IQuestionText | IQuestionSensitiveInput;

export interface IQuestionResStar {
  type: EQuestionItemType.Star;
  model: IQuestionStar;
  value: IQuestionOptionItem[];
}

export interface IQuestionResOption {
  type: EQuestionItemType.Option;
  model: IQuestionOption;
  value: IQuestionOptionItem[];
}

/**
 * 选项选中状态
 */
export enum EQuestionOptionStatus {
  /**
   * CHOOSED 已选择
   */
  CHOOSED = 0,
  /**
   * UNCHOOSED 未选择
   */
  UNCHOOSED = 1,
  /**
   * UNSHOWED 未显示
   */
  UNSHOWED = 2,
}

/**
 * 问卷问题每个选项保存结果
 */
export interface IQuestionResOptionItem {
  id: string;
  text: string;
  status: EQuestionOptionStatus;
}

/**
 * 答题保存结果：矩阵每行
 */
export interface IQuestionMatrixResRow {
  id: string;
  text: string;
  options: IQuestionResOptionItem[];
}

export interface IQuestionResMatrix {
  type: EQuestionItemType.Matrix;
  model: IQuestionMatrix;
  value: IQuestionMatrixResRow[];
}

export interface IQuestionResText {
  type: EQuestionItemType.Text;
  model: IQuestionText;
  value: string;
}

export interface IQuestionResSensitiveInput {
  type: EQuestionItemType.SensitiveInput;
  model: IQuestionSensitiveInput;
  value: string;
  confirm: boolean;
}

export type IQuestionResItem = IQuestionResStar | IQuestionResOption | IQuestionResMatrix | IQuestionResText | IQuestionResSensitiveInput;

export interface IQuestionSurveyModel {
  title: string;
  create_time: string;
  update_time: string;
  last_modified_by: string;
  index_group_id: string;
  project_id?: number;
  // 衡量标准，指标组类型，如csat、nps、ces(默认之前创建没有metric则为csat)
  metric: EMetricType;
  /**
   * 是否开启智能投放模式（默认关闭）
   */
  smart_mode: boolean;
  // 腾讯问卷ID
  tencent_survey_id?: string;
  pages: [
    {
      id: string;
      questions: IQuestionItem[];
    }
  ];
}
